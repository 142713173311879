import * as nprogress from "nprogress";

interface NProgressSettings {
  showSpinner: boolean | undefined;
  delay: number | undefined;
}
export interface INProgress {
  configure: (settings: NProgressSettings) => void;
  doStart: () => void;
  start: { (): void; (): void };
  doDone: () => void;
  done: { (): void; (): void };
  clearDelay: () => void;

  startDelay: ReturnType<typeof setTimeout> | undefined;
  settings: NProgressSettings;
}

const NProgress: INProgress = {
  ...(nprogress as unknown as INProgress),
  // eslint-disable-next-line import/namespace
  doStart: nprogress.start,
  // eslint-disable-next-line import/namespace
  doDone: nprogress.done,
  start: function () {
    this.clearDelay();
    this.startDelay = setTimeout(function () {
      NProgress.doStart();
    }, this.settings.delay ?? 0);
  },
  clearDelay: function () {
    if (this.startDelay) {
      clearTimeout(this.startDelay);
      this.startDelay = undefined;
    }
  },
  done: function () {
    this.clearDelay();
    this.doDone();
  },
};

NProgress.configure({ showSpinner: false, delay: 250 });

export default NProgress;
